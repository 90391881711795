// libraries
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// components
import { BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'constants/settings';

export const Breadcrumbs = ({ breadcrumbs }) => {
    function cropLastBreadcrumb(text) {
        const wordQuantityInLink = 4;
        const words = text.split(' ');
        if (words.length > wordQuantityInLink) {
            return `${words.slice(0, wordQuantityInLink).join(' ')}...`;
        }
        return text;
    }

    return (
        <StyledBreadCrumbs>
            {breadcrumbs.map(({ text, url }, index) => {
                return (
                    <li key={text}>
                        {index < breadcrumbs.length - 1 ? (
                            <Link to={url} className="home-breadcrumb">
                                <span dangerouslySetInnerHTML={{ __html: text }} />
                                <meta content={index + 1} />
                            </Link>
                        ) : (
                            <span dangerouslySetInnerHTML={{ __html: cropLastBreadcrumb(text) }} />
                        )}
                        {index < breadcrumbs.length - 1 && (
                            <StyledBreadcrumbsSeparator>&#47;</StyledBreadcrumbsSeparator>
                        )}
                    </li>
                );
            })}
        </StyledBreadCrumbs>
    );
};

const StyledBreadCrumbs = styled.ul`
    display: flex;
    margin: 16px 0 24px;
    padding: 0;
    list-style: none;

    ${BREAKPOINT.md} {
        margin-top: 48px;
    }

    li {
        font-size: 14px;
        letter-spacing: 0.5px;
        white-space: nowrap;

        ${BREAKPOINT.md} {
            font-size: 16px;
            letter-spacing: 1px;
        }

        a,
        span {
            color: ${COLORS.dark};
        }
    }

    li:last-child {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            color: ${COLORS.pastelSecondary};
        }
    }
`;

const StyledBreadcrumbsSeparator = styled.span`
    padding: 0 4px;
`;
