// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { BREAKPOINT } from 'components/UI/Grid';
import { BackLink } from 'components/Blog/BackLink';
// constants
import { COLORS } from 'constants/settings';
// static
import dialog from 'assets/images/svg-icons/dialog.svg';

export const ActionsBlock = ({ commentsCount }) => {
    return (
        <StyledActionsBlock>
            <BackLink link="/blog/" />
            <ActionsContent>
                <ActionsBlockItem aria-label={`Comments count ${commentsCount}`}>
                    <img src={dialog} alt="" /> {commentsCount}
                </ActionsBlockItem>
            </ActionsContent>
        </StyledActionsBlock>
    );
};

const StyledActionsBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 24px 0;

    ${BREAKPOINT.md} {
        margin: 24px 48px;
    }
`;

const ActionsContent = styled.div`
    display: flex;
    gap: 16px;
    color: ${COLORS.pastelSecondary};
    font-size: 14px;
`;

const ActionsBlockItem = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
