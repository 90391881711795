// libraries
import * as yup from 'yup';
// constants
import { COMMENT_FORM_MESSAGES } from 'constants/settings';

const config = {
    commentMin: 2,
    commentMax: 500,
};

export const formValidationSchema = {
    name: yup
        .string()
        .trim()
        .required(COMMENT_FORM_MESSAGES.name.required)
        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, COMMENT_FORM_MESSAGES.name.notValid),
    email: yup
        .string()
        .trim()
        .required(COMMENT_FORM_MESSAGES.email.required)
        .email(COMMENT_FORM_MESSAGES.email.notValid),
    comment: yup
        .string()
        .required(COMMENT_FORM_MESSAGES.comment.required)
        .min(config.commentMin, COMMENT_FORM_MESSAGES.comment.min)
        .max(config.commentMax, COMMENT_FORM_MESSAGES.comment.max),
};
