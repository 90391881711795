// libraries
import React from 'react';
import 'normalize.css';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// components
import { SEO } from 'components/SEO';
import { GlobalGridVars, BREAKPOINT, Container } from 'components/UI/Grid';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { Layout } from 'components/Layout';
import { TextBlogWysiwyg } from 'components/Blog/TextBlogWysiwyg';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ActionsBlock } from 'components/Blog/ActionsBlock';
import { AuthorBlock } from 'components/Blog/AuthorBlock';
import { ShareBlock } from 'components/Blog/ShareBlock';
import { Comments } from 'components/Blog/Comments';
import { SimilarPost } from 'components/Blog/SimilarPost';
// constants
import { COLORS } from 'constants/settings';
// hooks
import { useGetPosts } from 'hooks/use-get-posts';

const Post = ({ data, location, pageContext }) => {
    const { title, content, slug, postComments, postAuthor, databaseId, categories, postMainCategory } = data.wpPost;
    const newCategories = categories.nodes.filter((i) => i.name !== 'Blog');
    const avgSpeedReading = 1500;
    const { author } = postAuthor;
    const mainCategory = postMainCategory.mainCategory === null ? categories.nodes[0] : postMainCategory.mainCategory;
    const allPosts = useGetPosts();

    const maxSimilarPostsInRow = 3;
    const maxSimilarPostsInRowWithCurrent = 4;
    let iterations = 0;
    const maxIterations = 10;
    const similarPosts = [];
    const postsOfCurrentCategory = allPosts.filter((post) =>
        post.categories.nodes.some((node) => node.slug === mainCategory.slug && post.databaseId !== databaseId)
    );
    const postsOfCurrentCategoryLength = postsOfCurrentCategory.length;
    const indexesOfPosts = [];
    const setRandomPost = () => {
        return Math.floor(Math.random() * postsOfCurrentCategoryLength);
    };

    if (postsOfCurrentCategoryLength <= maxSimilarPostsInRowWithCurrent) {
        similarPosts.push(...postsOfCurrentCategory);
    } else {
        while (similarPosts.length < maxSimilarPostsInRow && iterations < maxIterations) {
            const index = setRandomPost();
            if (!indexesOfPosts.includes(index)) {
                similarPosts.push(postsOfCurrentCategory[index]);
                indexesOfPosts.push(index);
            }
            iterations++;
        }
    }

    const breadcrumbs = [
        {
            text: 'Blog',
            url: '/blog/',
        },
        ...(newCategories.length > 0
            ? [
                  {
                      text: mainCategory.title || mainCategory.name,
                      url: `/blog/?tab=${mainCategory.slug}`,
                  },
              ]
            : []),
        {
            text: title,
            url: `/${slug}/`,
        },
    ];

    return (
        <Layout pageType={pageContext.pageType}>
            <GlobalStyles />
            <GlobalGridVars />
            <StyledPost>
                <Container>
                    <ContentWrapper>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <StyledPostData>
                            Tiempo de lectura {Math.round(content.length / avgSpeedReading)} min{' '}
                            {newCategories.length > 0 && ' • '}
                            {newCategories.map((object) => object.name).join(' • ')}
                        </StyledPostData>
                        <PostTitle>{title}</PostTitle>
                        <TextBlogWysiwyg as="section" dangerouslySetInnerHTML={{ __html: content }} />
                        <ActionsBlock commentsCount={postComments.comments ? postComments.comments.length : 0} />
                        <ShareBlock currentPostUrl={`https://plazo.es/blog/${slug}`} />
                        {author ? (
                            <AuthorBlock
                                name={author.title}
                                description={author.content}
                                slug={author.slug}
                                avatar={author.postAuthorAvatar.avatar}
                            />
                        ) : null}
                        {postComments ? (
                            <Comments comments={postComments.comments} location={location} postId={databaseId} />
                        ) : null}
                    </ContentWrapper>
                    {similarPosts ? <SimilarPost items={similarPosts} /> : null}
                </Container>
            </StyledPost>
        </Layout>
    );
};
export default Post;

export const Head = ({ data }) => {
    return <SEO data={data.wpPost.seo} />;
};

const StyledPost = styled.section`
    padding-top: 56px;
    overflow: hidden;

    ${BREAKPOINT.md} {
        padding-top: 72px;
    }

    hr {
        height: 1px;
        margin-top: 60px;
        background: ${COLORS.mediumSecondary};
        border: none;

        ${BREAKPOINT.md} {
            margin-top: 80px;
        }
    }
`;

const ContentWrapper = styled.div`
    ${BREAKPOINT.md} {
        max-width: 800px;
        margin: auto;
    }
`;

const StyledPostData = styled.span`
    display: block;
    margin-bottom: 24px;
    color: ${COLORS.pastelSecondary};
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;

    ${BREAKPOINT.md} {
        font-size: 18px;
    }
`;

const PostTitle = styled.h1`
    margin: 0;
    padding: 24px 0;
    font-size: 34px;

    ${BREAKPOINT.md} {
        padding: 24px 0 16px;
        font-size: 48px;
    }
`;

export const query = graphql`
    query MyQuery($id: String!) {
        wpPost(id: { eq: $id }) {
            id
            title
            content
            slug
            uri
            databaseId
            date(formatString: "DD.MM.YYYY", locale: "ES")
            seo {
                canonical
                metaDesc
                metaRobotsNofollow
                metaRobotsNoindex
                title
            }
            categories {
                nodes {
                    id
                    name
                    slug
                }
            }
            postComments {
                comments {
                    date
                    user
                    comment
                }
            }
            postAuthor {
                author {
                    ... on WpPostAuthor {
                        id
                        slug
                        title
                        content
                        postAuthorAvatar {
                            avatar {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
            postMainCategory {
                mainCategory {
                    ... on WpPostCategory {
                        id
                        title
                        slug
                    }
                }
            }
        }
    }
`;
