// libraries
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as yup from 'yup';
// components
import { Button } from 'components/UI/Button';
import { InputGroup } from 'components/UI/InputGroup';
import { TextareaGroup } from 'components/UI/TextareaGroup';
import { BREAKPOINT } from 'components/UI/Grid';
// constants
import { API_URL } from 'api';
import { COLORS, COMMENT_FORM_MESSAGES } from 'constants/settings';
// helpers
import { formValidationSchema } from 'helpers/formValidationSchema';
// static
import error from 'assets/images/svg-icons/send-error.svg';
import success from 'assets/images/svg-icons/send-success.svg';

const schema = yup.object({
    name: formValidationSchema.name,
    email: formValidationSchema.email,
    comment: formValidationSchema.comment,
});

export const Form = ({ location, postId }) => {
    const [errorSend, setErrorSend] = useState(false);
    const [successSend, setSuccessSend] = useState(false);
    const [serverValidationErrors, setServerValidationErrors] = useState({});
    const currentPostUrl = location.href;
    const hasValidationErrors = (errors) => {
        return ['name', 'email', 'comment'].some((field) => errors?.[field]?.message.length);
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({ mode: 'onBlur', resolver: yupResolver(schema) });

    const submitData = async (data) => {
        setErrorSend(false);
        setServerValidationErrors({});
        const sendData = {
            ...data,
            currentPostUrl,
            postId,
        };

        try {
            const resp = await axios.post(API_URL.sendCommentsForm, sendData);
            if (resp.data.errors) {
                setServerValidationErrors(resp.data.errors);
            }
            if (resp.data.status) {
                setSuccessSend(true);
                reset();
            }
        } catch (err) {
            setErrorSend(true);
        }
    };

    const onSubmit = (data) => {
        if (typeof window !== 'undefined') {
            window.grecaptcha.ready(async () => {
                const response = await window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_TOKEN, {
                    action: 'submit',
                });
                const token = await response;
                const sendData = { ...data, ...{ recaptchaToken: token } };
                submitData(sendData);
            });
        }
    };

    useEffect(() => {
        if (hasValidationErrors(errors)) {
            setSuccessSend(false);
        }
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <StyledColumnInput>
                <InputGroup
                    {...register('name')}
                    label="Nombre y apellido"
                    placeholder="Escribe tu nombre aquí"
                    variant="blog"
                    errorMessage={errors?.name?.message || serverValidationErrors.name}
                />
                <InputGroup
                    {...register('email')}
                    type="email"
                    label="Email"
                    placeholder="Escribe tu email"
                    variant="blog"
                    errorMessage={errors?.email?.message || serverValidationErrors.email}
                />
            </StyledColumnInput>
            <TextareaGroup
                {...register('comment')}
                label="Comentario"
                rows={10}
                cols={40}
                placeholder="Deja un comentario"
                variant="blog"
                errorMessage={errors?.comment?.message || serverValidationErrors.comment}
            />
            <StyledButtonComment as="button" type="submit" variant="outlined" isValid={isValid}>
                Enviar comentario
            </StyledButtonComment>
            {errorSend || hasValidationErrors(errors) ? (
                <ErrorMessage>
                    <img src={error} alt="" />
                    {COMMENT_FORM_MESSAGES.errorSending}
                </ErrorMessage>
            ) : null}
            {successSend && !hasValidationErrors(errors) ? (
                <SuccessMessage>
                    <img src={success} alt="" />
                    {COMMENT_FORM_MESSAGES.successSending}
                </SuccessMessage>
            ) : null}
        </form>
    );
};

const StyledColumnInput = styled.div`
    ${BREAKPOINT.md} {
        display: flex;
        gap: 24px;
        align-items: baseline;
    }
`;

const StyledButtonComment = styled(Button)`
    display: block;
    width: 100%;
    margin: 0 auto;

    ${BREAKPOINT.md} {
        width: 100%;
        max-width: 348px;
        margin-bottom: 40px;
    }
`;

const sendMessage = styled.span`
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 24px auto;
    padding: 8px;
    border-radius: 8px;

    ${BREAKPOINT.md} {
        margin: 40px auto;
    }

    img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
    }
`;

const ErrorMessage = styled(sendMessage)`
    color: ${COLORS.dangerDark};
    background: ${COLORS.dangerLight};
`;

const SuccessMessage = styled(sendMessage)`
    color: ${COLORS.blogText};
    letter-spacing: 0.5px;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 15%), 0 6px 10px 4px rgb(0 0 0 / 10%);
`;
