// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { BREAKPOINT } from 'components/UI/Grid';
import { Card } from 'components/Blog/Card';
// constants
import { COLORS } from 'constants/settings';
import { Slider } from 'components/UI/Slider';

export const SimilarPost = ({ items }) => {
    const avgSpeedReading = 1500;
    const options = {
        drag: 'free',
        mediaQuery: 'min',
        type: 'loop',
        perPage: 1,
        snap: true,
        start: 1,
        arrows: false,
        gap: '16px',
        breakpoints: {
            768: {
                destroy: true,
            },
        },
    };
    return (
        <StyledSimilarPostWrapper>
            {items && items.length > 0 ? (
                <>
                    <StyledTitleSimilarPost>Puede que te apetezca leen</StyledTitleSimilarPost>
                    <StyledSliderWrapper>
                        <Slider
                            options={options}
                            slides={items.map((el, index) => (
                                <Card
                                    key={index}
                                    tags={el.categories}
                                    title={el.title}
                                    date={el.date}
                                    url={el.uri}
                                    content={el.excerpt}
                                    featuredImage={el.featuredImage && el.featuredImage.node.localFile.publicURL}
                                    timeReading={Math.round(el.content.length / avgSpeedReading)}
                                />
                            ))}
                        />
                    </StyledSliderWrapper>
                </>
            ) : null}
        </StyledSimilarPostWrapper>
    );
};

const StyledSimilarPostWrapper = styled.div`
    padding-top: 24px;

    ${BREAKPOINT.md} {
        padding-top: 16px;
    }

    ${BREAKPOINT.xl} {
        max-width: 1072px;
        margin: auto;
    }
`;
const StyledTitleSimilarPost = styled.h3`
    font-size: 28px;
    line-height: 1.3;

    ${BREAKPOINT.md} {
        font-size: 40px;
    }
`;

const StyledSliderWrapper = styled.div`
    margin-top: 32px;

    ${BREAKPOINT.md} {
        flex-direction: row;
        margin-top: 40px;
    }

    li {
        display: flex;
        margin-bottom: 16px;
    }

    .splide__track {
        margin-right: 8px;
        overflow: visible;

        ${BREAKPOINT.xl} {
            margin-right: 0;
        }
    }

    .splide__pagination {
        position: static;
        display: flex;
        flex-wrap: nowrap;
    }

    .splide__pagination__page {
        bottom: unset;
        width: 100px;
        height: 4px;
        margin: 0;
        background: ${COLORS.secondary};
        border-radius: 0;

        &.is-active {
            background: ${COLORS.pastelSecondary};
            transform: unset;
        }
    }

    .splide.is-initialized:not(.is-active) .splide__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
        justify-content: center;
    }
`;
