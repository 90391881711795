// libraries
import styled from 'styled-components';
// components
import { BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'constants/settings';

export const TextBlogWysiwyg = styled.div`
    padding-bottom: 24px;

    ${BREAKPOINT.md} {
        padding-bottom: 40px;
    }

    h2,
    h3,
    h4 {
        padding-top: 32px;

        ${BREAKPOINT.md} {
            padding-top: 40px;
        }
    }

    h1 {
        font-size: 34px;

        ${BREAKPOINT.md} {
            font-size: 48px;
        }
    }

    h2 {
        font-size: 28px;

        ${BREAKPOINT.md} {
            font-size: 40px;
        }
    }

    h3 {
        font-size: 24px;

        ${BREAKPOINT.md} {
            font-size: 32px;
        }
    }

    h4 {
        font-size: 22px;

        ${BREAKPOINT.md} {
            font-size: 28px;
        }
    }

    p {
        padding: 16px 0 0;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;

        ${BREAKPOINT.md} {
            font-size: 18px;
            letter-spacing: 1px;
        }
    }

    a {
        color: ${COLORS.dark};
        text-decoration: underline;
    }

    img {
        height: auto;
    }

    figcaption {
        font-size: 12px;
        letter-spacing: 0.5px;

        ${BREAKPOINT.md} {
            font-size: 14px;
        }
    }

    strong {
        font-weight: 600;
    }

    ol,
    ul {
        li {
            margin-bottom: 16px;
            letter-spacing: 0.5px;

            ${BREAKPOINT.md} {
                font-size: 18px;
                letter-spacing: 1px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    iframe[src*='youtube.com'] {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
    }
`;
