// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { Form } from '../../Forms/BlogPostForm';
import { Comment } from './Comment';
import { BREAKPOINT } from 'components/UI/Grid';

export const Comments = ({ comments, location, postId }) => {
    return (
        <>
            <StyledTitle>Comentarios</StyledTitle>
            <Form location={location} postId={postId} />
            {comments ? (
                <StyledCommentsList>
                    {comments.map(({ user, date, comment }, index) => {
                        const formattedDate = date.split('.,').join('');
                        return <Comment key={index} user={user} date={formattedDate} comment={comment} />;
                    })}
                </StyledCommentsList>
            ) : null}
        </>
    );
};

const StyledTitle = styled.h3`
    font-size: 24px;

    ${BREAKPOINT.md} {
        font-size: 32px;
    }
`;

const StyledCommentsList = styled.div`
    padding: 24px 0;

    ${BREAKPOINT.md} {
        padding: 0 0 72px;
    }
`;
