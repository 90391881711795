// libraries
import React from 'react';
import styled from 'styled-components';
// constants
import { BREAKPOINT } from 'components/UI/Grid';
// static
import facebook from 'assets/images/svg-icons/facebook-dark.svg';
import x from 'assets/images/svg-icons/x-dark.svg';
import telegram from 'assets/images/svg-icons/telegram.svg';
import whatsapp from 'assets/images/svg-icons/whatsapp.svg';
import linkedin from 'assets/images/svg-icons/linkedin-dark.svg';
import mail from 'assets/images/svg-icons/mail-icon.svg';

export const ShareBlock = ({ currentPostUrl }) => {
    const shareItems = [
        {
            href: `https://www.facebook.com/sharer/sharer.php?u=${currentPostUrl}`,
            img: facebook,
            alt: 'Facebook',
        },
        { href: `https://twitter.com/intent/tweet?text=${currentPostUrl}`, img: x, alt: 'X' },
        { href: `https://t.me/share/url?url=${currentPostUrl}`, img: telegram, alt: 'Telegram' },
        {
            href: `https://api.whatsapp.com/send?text=${currentPostUrl}`,
            img: whatsapp,
            alt: 'Whatsapp',
        },
        {
            href: `https://www.linkedin.com/sharing/share-offsite/?url=${currentPostUrl}`,
            img: linkedin,
            alt: 'Linkedin',
        },
        { href: `mailto:?body=${currentPostUrl} ${currentPostUrl}`, img: mail, alt: 'Mail' },
    ];
    return (
        <StyledShareBlock>
            <p>¿Te ha gustado? Compártelo</p>
            <ShareItems>
                {shareItems.map((item, index) => (
                    <li key={index}>
                        <a href={item.href} target="_blank" rel="noreferrer">
                            <img src={item.img} alt={item.alt} />
                        </a>
                    </li>
                ))}
            </ShareItems>
        </StyledShareBlock>
    );
};

const StyledShareBlock = styled.div`
    p {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }
`;

const ShareItems = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding-left: 0;
    list-style: none;

    ${BREAKPOINT.md} {
        justify-content: center;
    }

    li {
        a {
            display: block;
            width: 24px;
            height: 24px;
            margin: 8px 12px;

            ${BREAKPOINT.sm} {
                margin: 8px 20px;
            }
        }
    }
`;
