// libraries
import React from 'react';
import styled from 'styled-components';
// constants
import { COLORS } from 'constants/settings';

export const Comment = ({ user, date, comment }) => {
    return (
        <StyledComment>
            <StyledCommentDate>{date}</StyledCommentDate>
            <StyledCommentName>{user}</StyledCommentName>
            <StyledCommentMessage>{comment}</StyledCommentMessage>
        </StyledComment>
    );
};

const StyledComment = styled.div`
    margin-bottom: 16px;

    :last-child {
        margin-bottom: 0;
    }
`;

const StyledCommentDate = styled.div`
    color: ${COLORS.pastelSecondary};
    font-size: 14px;
`;

const StyledCommentName = styled.div`
    margin-top: 8px;
    color: ${COLORS.dark};
    font-weight: 600;
    font-size: 16px;
`;

const StyledCommentMessage = styled.div`
    margin-top: 8px;
    color: ${COLORS.blogText};
    font-size: 16px;
`;
